import React,{useEffect,useState,useCallback} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { PsyItem } from "../../components";
import { Link, useHistory } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBest , getPage} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from "react-router";
import {IS_SUBMITED,SET_FORMULE,SET_FORMULE_UPDATE} from "../../constants/actions"
import { apiClient } from "../../actions/api"
import { fetchAbonnement } from "../../actions/api_interface"
import moment from 'moment'

import{useGoCardlessDropin, GoCardlessDropinOptions, GoCardlessDropinOnSuccess} from '@gocardless/react-dropin';



const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);
    const isupdatetherapeut = queryParams.get('p');

    const [value, setValue] = useState(0);

    const [show,setShow]=useState(false);
    const { data,pageinfo } = useSelector(state => state.mixedmode);
    const { user,formule,formuleupdate } = useSelector(state => state.user);
    const { issubmited } = useSelector(state => state.global)

    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const [therapeute,setTherapeut]=useState([{}]);
    const [curentFormule,setcurentFormule]=useState({});
    const [readplus,setReadPlus]=useState(0);
    const [isError, setError] = useState(false);
    const [showpaiement, setshowpaiement] = useState(false);
    const [ErrorUser,setErrorUser]=useState(false);
    const [IDFOLOW,setIDFOLOW]=useState('');
    const [IDMANDAT,setIDMANDAT]=useState('');
    const [hasabonoed,sethasabonoed]=useState(false);

    const [loading,       setloading]       = useState(false);
    const [isabonement,   setisabonement]   = useState(false);
    const [isNotReferent, setIsNotReferent] = useState(false);
    const [currentReferent, setCurrentReferent] = useState(false);
    const [reservation,   setreservation]   = useState(false);
    const [okpaiement,    setokpaiement]    = useState(false);

    const handleClose = () => setShow(false);


    const currentlangStorage = localStorage.getItem("currentlang");

    const [langset, setLangset] = useState(false);
    const [tab, setTab] = useState(1);
    const [tabins, setTabins] = useState(1);
    const [active,setActive]=useState(2);
    const [active2,setActive2]=useState(1);
    const [active3,setActive3]=useState(1);
    const [iflivreur,setIflivreur]=useState(false);
    const [avgnote,setavgnote]=useState(0);





    const QuickFixButtonWithCondition = () => { // looks like not allowed to follow current coding style. Is it in ternary case ?
      if (isNotReferent) {
        return null
      }

      return (
        <Button
          className="ps-btn ps-btn--fullwidth ps-btn-dark ommp00" type="button" disabled>
          Payer {parseFloat(curentFormule?.price).toFixed(2)} €
        </Button>
      )
    }


    useEffect(() => {
      if (currentReferent && therapeute && currentReferent.user_id !== therapeute.user_id) {
        setIsNotReferent(true)
      }
    }, [currentReferent, therapeute])


    useEffect(() => {
      fetchAbonnement(user, (abonnement) => {
        setCurrentReferent(abonnement.referent)
      })
    }, [])


    useEffect(()=>{



      if(!user?.adresse || !user?.codepostal|| !user?.city  ){
        history.push('/profile/?redirect=1')
      }


      getuser()
      if(!user?.user_id){
           history.push('/');
      }
      if (user?.active===0) {
            history.push('/infos-patient');
      }

       handlerVerification()

       if(isupdatetherapeut==='true'){
          setcurentFormule(formuleupdate?.formule)
          setTherapeut(formuleupdate?.therapeut)
       }
       else{
         setcurentFormule(formule?.formule)
          setTherapeut(formule?.therapeut)
       }

       dispatch(getPage(56))

       let sum=0;
           for (var i = 0; i < formule?.therapeut?.avg.length; i++) {
                sum=sum+parseInt(formule?.therapeut?.avg[i].note)
           }
          if(sum>0){
            setavgnote(sum/formule?.therapeut?.avg.length)
      }


    },[user])


    function getuser(){


       let params={
              token:user.api_token,
              api_token:user.api_token,
          }


          apiClient().post(`/get/user`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                  if(res.data.user?.adolescent===1){

                     setErrorUser(true)
                     setshowpaiement(false)


                  }
                  else{
                    setErrorUser(false)
                    setshowpaiement(true)
                  }
              }
              else{

                setError(true)


              }

           })
    }

     const handleClick = (slug) => {
        history.push(slug);
    }



   const handleShow = (item) => {


      setShow(true);

    }



   const handleGoTo = (slug) => {
         history.push(slug)
    }



  const handleTabs=(a)=>{

             setActive(a)

    }

    const handleTabs2=(a)=>{

             setActive2(a)
             if(a==2){
               history.push("/devenir-vendeur/")
             }

    }
      const handleTabs3=(a)=>{

             setActive3(a)

    }




    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        history.push('/paiement')
        //dispatch(registerUser(formDataObj,'user',currentlang));


    }

    const isDebug = user ? ((user?.email || '').endsWith('@etiskapp.com') || user?.email === 'appli.patient@feel-better-company.com') : false;


    const handlersavepaiement = async (e) =>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())



    }


       const handlerVerification =  () => {

       let params={
              token:user.api_token,
              api_token:user.api_token,
          }

         apiClient().post(`/get/dossierpatient`, params)
           .then((res) => {
              if (res.data.success === 'ok') {
                 if(res.data.count<=0){
                     localStorage.setItem('dossierisokreturn', true);
                     history.push('/dossier-patient')
                 }
                 else{

                    localStorage.setItem('dossierisokreturn', false);
                 }


              }


           })

    }



    const handlersaveorder =  (billingobj,id_paiement,d,curency='EUR') => {

          setError(false)
          setloading(true)
          let params={
              patient_id:user?.user_id,
              therapeut_id:therapeute?.user_id,
              tarif_id:isupdatetherapeut==='true' ? formuleupdate?.tarif?.id :  formule?.tarif?.id,
              formule_id:isupdatetherapeut==='true' ? formuleupdate?.formule?.id : formule?.formule?.id,
              date_rdv:isupdatetherapeut==='true' ? formuleupdate?.date : formule?.date,
              heure_rdv:isupdatetherapeut==='true' ? formuleupdate?.heure : formule?.heure,
              idflow:IDFOLOW,
              mandat:d==true ? billingobj?.links?.mandate_request_mandate : IDMANDAT,
              id_paiement:id_paiement,

              total_price:parseFloat(curentFormule?.price).toFixed(2),
              token:user.api_token,
              api_token:user.api_token,
              curency:curency,
              isupdate:isupdatetherapeut==='true' ? true : false

          }

          if(isDebug){
            params.id_paiement = 1;
            params.mandat = 1;
          }


          apiClient().post(`/save/reservation`,params )
           .then((res) => {

              if(res.data.success=='ok'){
                  setloading(false)
                  dispatch({ type: SET_FORMULE, payload: {} })

                  history.push('/paiement-valide/')

              }
              else{

                setError(true)
                setloading(false)


              }

           })

           //history.push('/paiement-valide')

    }




const getfolowid = () => {

//"BRF0000KZSVE63CETWT7JZSEW2DBT1JC"

  let params={
    token:user.api_token,
    api_token:user.api_token,
    user_id:user?.user_id
  }


  apiClient().post(`/init-paiement`, params)
  .then((res) => {

    if(res.data.success == true){
       if(res.data.isabonement){
          setisabonement(true)
          setreservation(res.data.reservation)
       }
       else{

          if(res.data.folow_id == ''){
            setIDMANDAT(res.data.id_mandat)
          }
          else{
            setIDFOLOW(res.data.folow_id)
          }
       }
    }
    else{
      setError(true)
    }
  })
}

const getmandatid = () => {



//"BRF0000KZSVE63CETWT7JZSEW2DBT1JC"

 let params={
              token:user.api_token,
              api_token:user.api_token,
              user_id:user?.user_id
          }


          apiClient().post(`/get-mandat`,params )
           .then((res) => {

              if(res.data.success==true){
                 if(res.data.isabonement){
                    setisabonement(true)

                 }
                 else{

                    if(res.data.id_mandat!==''){

                        setIDMANDAT(res.data.id_mandat)
                    }




                 }


              }
              else{

                setError(true)


              }

           })



}
     useEffect(() => {

        if(langset == false){

           if(isupdatetherapeut === 'true'){

             getmandatid()
           }
           else{
             getfolowid()
           }

            setLangset(true)
        }



    }, [])

  const handleChangecheckbox = event => {
    if (event.target.checked) {
       setokpaiement(true)
    } else {
     setokpaiement(false)
    }

  };


  const paydirect = (e) => {

        setloading(true)
           let params={
              token:user.api_token,
              api_token:user.api_token,
              mandat_id:IDMANDAT,
              amount:parseFloat(curentFormule?.price).toFixed(2)
          };


          apiClient().post(`/save-transaction`,params )
           .then((res) => {

              if(res.data.success == true){
                handlersaveorder('',res.data.id_paiement,false)
              }
              else{

                setError(true)
                setloading(false)


              }

           })


  };


const config : GoCardlessDropinOptions = ({
    billingRequestFlowID: "BRF0000KZSVE63CETWT7JZSEW2DBT1JC",
    environment: "live", // either live or sandbox
    onSuccess: (billingRequest, billingRequestFlow) => {console.log(billingRequestFlow)},
    onExit: (error, metadata) => {console.log(metadata)},
});


const DropinButton = (options: GoCardlessDropinOptions) => {
  const { open } = useGoCardlessDropin({config});

  return (
    <button type="button" onClick={() => open()}>
      Start Dropin for <code>{options.billingRequestFlowID}</code> in{" "}
      <code>{options.environment}</code>
    </button>
  );
};


const handler = useGoCardlessDropin({
    billingRequestFlowID: IDFOLOW,
    environment: "live",
    onSuccess: (billingRequest, billingRequestFlow) => {



           setloading(true)
           let params={
              token:user.api_token,
              api_token:user.api_token,
              mandat_id:billingRequest?.links?.mandate_request_mandate,
              amount:parseFloat(curentFormule?.price).toFixed(2),
              currency:billingRequest?.mandate_request?.currency

          };


          apiClient().post(`/save-transaction`,params )
           .then((res) => {

              if(res.data.success==true){

                   handlersaveorder(billingRequest,res.data.id_paiement,true,billingRequest?.mandate_request?.currency)
              }
              else{

                setError(true)
                setloading(false)


              }

           })



    },
    onExit: (error, metadata) => {


    },
});



const returnformpaiement = () => {
return(

                    <Row>
                    <Col lg={{ span: 12 }} className="text-center mx-auto " >


                        <div className='center'>
                              <img className='ommp00'  src='/images/004888i12b.png' />
                        </div>

                        <div className='center '><img className='ommp01' src='/images/visa-mastercard-icon-5.png' /></div>
                           <br/>

                          <h5 style={{color:'#000', textAlign:'center'}}>Paiement par carte bancaire</h5>



                           <Form className="mb-5" onSubmit={handlersavepaiement}  >

                          {
                            /*


                            <Form.Group className="mb-3 row flexbetweens" >

                            <div className='col-md-5'><label> Numéro de carte bancaire*</label></div>
                            <div className='col-md-7'>
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="42424242.." />
                               </div>
                             </Form.Group>




                            <Form.Group className="mb-3 row flexbetweens" >

                            <div className='col-md-5'><label> Date d'expiration*</label></div>
                            <div className='col-md-7'>
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="MM/AA" />
                               </div>
                             </Form.Group>


                            <Form.Group className="mb-3 row flexbetweens" >

                            <div className='col-md-5'><label> Code de vérification*</label></div>
                            <div className='col-md-7'>
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="123" />
                               </div>
                             </Form.Group>



                            */
                          }





<div style={{marginTop:'20px'}}></div>

                        <Form.Group className="mb-3" >


                               <br/>

                        {
                           (IDFOLOW || IDMANDAT) &&

                           <div>

                              {
                                !loading ?

                                  <div>

                                    {
                                      !isNotReferent &&
                                      <div className='js889' style={{    marginBottom: '14px'}}>
                                        <input type='checkbox' name='adolesant' value='1' onChange={handleChangecheckbox} />
                                        <span style={{paddingLeft: '8px'}}>J’accepte de réaliser une visioconférence*</span>
                                      </div>
                                    }


                                    {
                                      okpaiement ?
                                      <div>
                                        {
                                          IDFOLOW && !isNotReferent &&
                                             <Button
                                              className="ps-btn ps-btn--fullwidth ps-btn-dark ommp00" type="button" onClick={()=>handler.open()}>
                                              Payer {parseFloat(curentFormule?.price).toFixed(2)} €
                                             </Button>
                                        }

                                       {
                                          IDMANDAT && !isNotReferent &&
                                             <Button
                                              className="ps-btn ps-btn--fullwidth ps-btn-dark ommp00" type="button" onClick={(e)=>paydirect(e)}>
                                              Payer {parseFloat(curentFormule?.price).toFixed(2)} €
                                             </Button>
                                        }


                                      </div>
                                     :
                                      <QuickFixButtonWithCondition />

                                    }



                                 </div>



                                   :


                                   <div className='alert alert-warning'>
                                      <p> Opération en cours de traitement..</p>
                                      <div className='center'>
                                         <img src='/images/157446935584697.gif' style={{width:'90px'}} />
                                      </div>

                                   </div>


                              }



                            </div>
                        }



                         </Form.Group>


                           {
                            isError &&
                            <div className="alert alert-danger" style={{marginTop:'40px'}}>
                               Une erreur est survenue veuillez réessayer plus tard  !
                            </div>
                           }

                           {
                            isabonement &&
                            <div className="alert alert-danger" style={{marginTop:'40px'}}>

                                Vous êtes déjà abonné au  {reservation?.formule?.titre} - {reservation?.tarif?.titre}
                            </div>
                           }

                           {
                            isNotReferent &&
                            <div className="alert alert-danger" style={{marginTop:'40px'}}>
                              Ce praticien n'est pas votre référent
                            </div>
                           }



                        </Form>


                        {
                          !isabonement &&

                           <p className='alert alert-success' dangerouslySetInnerHTML={{ __html:pageinfo?.contenu}} ></p>

                        }



                    </Col>
                </Row>

  )

}



const returnErrorAdolescent = () => {
     return (

        <div className='alert alert-danger' style={{    display: 'flex',alignItems: 'center'}}><span style={{fontSize: '24px',marginRight: '10px'}}><i className='fa fa-remove'></i></span>Votre compte est en cours de vérification.</div>

    )
}



    return (
        <div className="home paiementtherapeut" >


          <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=>history.push(`/`)}>Accueil</a></li><li>praticien</li><li>Paiement</li></ul></div></div></div>
          </section>



 <section className="section2  " style={{paddingTop:'10px'}} >
    <div class="holder">
    <ul class="SteppedProgress">
    <li class="boxed  complete   "><span>Choix du praticien</span></li>
    <li class="boxed  complete  "><span>Infos patient</span></li>
    <li class="boxed complete "><span>Paiement</span></li>
    <li class="boxed  "><span>C'est terminé</span></li>
    </ul>
    </div>

 </section>

          <section className="section2 thisprofil listtherapeutes " >
                <Container>

                  <div className='row'>

                     <Col md={6} >

                     <div className='therapeutescontent'>





        <div style={{display:'block',paddingTop:'0px'}} >


           {
             showpaiement &&
            returnformpaiement()
           }

           {
             ErrorUser &&
            returnErrorAdolescent()
           }






        </div>


               </div>




                     </Col>

                     <Col md={1} >

                     </Col>

                     <Col md={5} >


                  <div className='therapeutescontent'>

                      <Row className=''>
                        <Col md={12} >
                             <div className='flexitems row'>
                                 <div className='itemimg col-md-3' style={{backgroundImage:therapeute?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+therapeute?.picture})` : "url('/images/default-profile.png')"}}>

                                 </div>
                                  <div className='itemheader col-md-8' >
                                    <h3>{therapeute?.first_name} {therapeute?.last_name} </h3>


                                 <div className="ps-product--detail ">
                                  <div className="ps-product__meta">
                                    <div className="ps-product__rating" >
                                        <div className="br-wrapper br-theme-fontawesome-stars">
                                        </div>
                                    </div>

                                </div>
                                </div>

                                  <div className=''>

                                  {
                                    isupdatetherapeut==='true' ?
                                    <div>

                                         <div className='item' style={{paddingBottom:'10px'}}>Date : <strong> {moment.utc(formuleupdate?.fullDate).local().format("DD/MM/YYYY")}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Heure : <strong>{moment.utc(formuleupdate?.fullDate).local().format("HH:mm")}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Votre besoin : <strong>{formuleupdate?.tarif?.titre}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Votre formule : <strong> {formuleupdate?.formule?.titre}</strong></div>
                                     </div>

                                    :
                                    <div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Date : <strong> {moment.utc(formule?.fullDate).local().format("DD/MM/YYYY")}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Heure : <strong>{moment.utc(formule?.fullDate).local().format("HH:mm")}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Votre besoin : <strong>{formule?.tarif?.titre}</strong></div>
                                         <div className='item' style={{paddingBottom:'10px'}}>Votre formule : <strong> {formule?.formule?.titre}</strong></div>
                                      </div>
                                  }




                                  </div>



                                 </div>
                             </div>




                        </Col>
                        <Col md={3} >

                           <div className='actionrdv'>

                               <div className="text-center" >



                                </div>

                           </div>

                        </Col>

                       </Row>



                    </div>


                <div className='therapeutescontent'>

                    <div className='flexrowtarifs row'>

                      <div className='item'>



                  <div className='pricebox' style={{boxShadow: 'none'}}>
                  <h3>{curentFormule?.titre}</h3>
                  <h4>{parseFloat(curentFormule?.price).toFixed(2)}€<span><sup>/séance</sup></span></h4>
                   <div className="pricebox-border2"> </div>
                  <div className='ttcontent'>
                  <ul className='col-9028-K'>

                       <li><i className='fa fa-check-circle icochecked'></i> {curentFormule?.temps} min</li>
                       <li><i className='fa fa-check-circle icochecked'></i> {curentFormule?.seance} séances</li>
                       <li><i className='fa fa-check-circle icochecked'></i> {curentFormule?.pack}</li>

                  </ul>

                  <label>
                     Description
                  </label>
                  <p>{ curentFormule?.id==readplus ?  curentFormule?.contenu : curentFormule?.shotconte}  <br/> { (curentFormule?.id!==readplus && curentFormule?.show==1) && <a href='javascript:void(0)' onClick={()=>setReadPlus(curentFormule?.id)}>Lire plus</a> } </p>




                  </div>
               </div>



                      </div>



                    </div>
                </div>



                     </Col>

                  </div>





                </Container>
          </section>













      <Modal show={show} onHide={handleClose} className='modelhermixtarif'   size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
          <CloseButton variant="dark" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>




        </Modal.Body>
        <Modal.Footer>


        </Modal.Footer>
      </Modal>
        </div>
    );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Page;
