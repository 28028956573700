import React,{ useState,useEffect,useRef } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion'
import { apiClient } from "../../../actions/api"
import { useParams } from "react-router";


const Page = () => {
    const dispatch = useDispatch();
    const { id } = useParams()
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)
    const [patientid, setpatientid] = useState(id)
    const fileInput = useRef(null)
    const fileInput2 = useRef(null)
    const fileInput3 = useRef(null)

    const [eventconsulation, setEventconsulation] = useState('')
    const [dossierpatient, setdossierpatient] = useState([])
    const [abonnement, setabonnement] = useState({})
     const [documents, setDocuments] = useState([]);



    const [counter, setCounter] = useState('')
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [questions, setQuestions] = useState([]);
    const [responses, setResponse] = useState([]);

     const [issucces, setissucces] = useState(false);
     const [isloading, setisloading] = useState(false);


    const handleCloseAlert = () => setShowAlert(false);

    useEffect(() => {
         setpatientid(id)
         getDossierpatient(id)
         getDossier(id)
         getDocuments(id)
    }, []);


        function getDossier(id) {
        let params={

              token:user.api_token,
              api_token:user.api_token,
               patient_id:id
          }


          apiClient().post(`/get/dossierpatient/therapeut`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                setQuestions(res.data.dossierpatients)


              }


           })
     }




    function getDossierpatient(id) {
        let params={

              token:user.api_token,
              api_token:user.api_token,
              patient_id:id
          }


          apiClient().post(`/get/dossier/patient`,params )
           .then((res) => {

              if(res.data.success=='ok'){
                setabonnement(res.data.abonnement)

              }


           })
    }




      function gotoCmd(id,item) {
       dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/vendeur/commande/detail/${id}`);
     }



  function getDocuments(id) {
        let params={

              token:user.api_token,
              api_token:user.api_token,
              type:'patient',
              patient_id:id

          }


          apiClient().post(`/get/documents/therapeut`,params )
           .then((res) => {

              if(res.data.success=='ok'){

                setDocuments(res.data.documents)


              }


           })
     }



    const handleClickfile = (t) => {
    if(t==1){

      fileInput.current.click()
    }
    else if(t==2){
        fileInput2.current.click()
    }
    else{

      fileInput3.current.click()

    }
  }


   const handleFileChange = (event,type) => {

      const formData =  new FormData();
      formData.append('file',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
      formData.append('type',type);
      formData.append('patient_id',patientid);

      setisloading(true)

       apiClient().post(`/save/documents/therapeut`,formData )
           .then((res) => {

              if(res.data.success=='ok'){

                 setDocuments(res.data.documents)
                 setissucces(true)
                 setisloading(false)


              }


           })

  }




    return (
        <div className="mescommandes register vosventes bg778635 " >

            <Container>
                <Row>
              <Col  className="text-center mx-auto my-5"  lg="3">

                  <MenuVendeur slug='mesrendezvous' />

                </Col>

                    <Col  className="text-center mx-auto my-5 menudashright"  >


                          <h3 className="mb-4" > Dossier patient</h3>




                      <div className='text-center'>



                      <Card.Img  style={{cursor:'pointer',width:100,height:100,backgroundImage:abonnement?.patient?.picture ? `url(${"https://feel-better-company.com/gestion/public/uploads/"+abonnement?.patient?.picture})` : "url('/images/default-profile.png')",borderRadius:50,backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />

                      <div class="col-md-4 mx-auto">
                           <strong>{abonnement?.patient?.name}</strong>
                      </div>
                      <br/>


                  </div>



                  <section>

                     <Table striped bordered hover variant="white ">

                       <tbody>

                         <tr>
                          <td>Méthode</td>
                          <td>{abonnement?.tarif?.titre}</td>

                         </tr>
                        <tr>
                          <td>Formule</td>
                          <td>{abonnement?.formule?.titre}</td>

                         </tr>

                          <tr>
                          <td>Date début</td>
                          <td>{moment(new Date(abonnement?.created_at)).format("DD/MM/YYYY")} </td>

                         </tr>
                         <tr>
                          <td>Séances</td>
                          <td><div className='alert alert-primary' style={{ display: 'none' }}>{parseInt(abonnement?.formule?.seance) - (parseInt(abonnement?.formule?.seance)-parseInt(abonnement?.nbreservation))}/{abonnement?.formule?.seance} séances effectuées</div></td>

                         </tr>


                       </tbody>


                     </Table>



                  </section>


                  <section>

                     <Table striped bordered hover variant="white ">

                       <tbody>

                         <tr>
                          <td>Nom et Prénom</td>
                          <td> <strong>{abonnement?.patient?.name}</strong></td>

                         </tr>

                       </tbody>


                     </Table>



                  </section>
<br/><br/>
              <section>


         <div className='contentsection'>

          <div className='alert alert-warning'><strong>Informations confidentielles  soumis au secret professionnel</strong></div>

 {
    questions?.map((item,key)=>(

<Accordion style={{marginBottom:'20px'}} >
  <Accordion.Item eventKey={key}>
    <Accordion.Header>{item?.question}</Accordion.Header>
    <Accordion.Body>

        <div className='flexitems'>

           {
            item.reponse==='true' ?
            <a href="javascript:void(0)"  class="btnlink marauto">Oui  <i class="fa fa-check"></i> </a>
            :
             <a href="javascript:void(0)" class="btnlink marauto btnlinkjaun" >Non <i class="fa fa-check"></i> </a>

           }



        </div>
         {
            item.reponse=='true' &&

               <p><br/> {item.motif}</p>

        }



    </Accordion.Body>
</Accordion.Item>

</Accordion>

    ))
 }

<br/>

<br/>


                             </div>



              </section>






 <div className='contentsection'>

             <h3 className="mb-4" > Documents</h3>


<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><strong>Documents patient</strong></Accordion.Header>
    <Accordion.Body>
   <br/>
    <div className='alert alert-primary'>Récupérer une note ou un écrit provenant de votre patient.</div>


        <br/> <br/>

  <Row className="mb-3"  >
      <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>

                                <th key="1">Document</th>
                                <th key="2">Date</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>

                    {
                        documents?.map((item,key)=>(

                          item.type===1 &&

                            <tr>

                              <td>{item.name}</td>
                              <td>{moment(new Date(item.created_at)).format("DD MMM YYYY")} {moment(new Date(item.created_at)).format("HH:mm:ss")}</td>

                              <td>
                                <div className="list-contr">

                                  <a  href={item?.url} download className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Télécharger <i class="fa fa-download"></i></a>



                               </div>
                              </td>

                          </tr>


                        ))
                      }

                        </tbody>



                      </Table>

              </div>

          </Row>



    </Accordion.Body>
</Accordion.Item>

</Accordion>
<br/>
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><strong>Documents praticien</strong></Accordion.Header>
    <Accordion.Body>
   <br/>
    <div className='alert alert-primary'>Déposer les notes ou exercices destinés à votre patient.</div>
    <div className='flexitems'>
           <a href="javascript:void(0)"  onClick={() => handleClickfile(1)}   class="btnlink marauto">Déposer <i class="fa fa-plus"></i>
            <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"
                accept='image/*'
                onChange={(e) => handleFileChange(e,3)}
                ref={fileInput}
            />
            </form>
           </a>

        </div>
        <br/> <br/>

  <Row className="mb-3"  >
      <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>

                                <th key="1">Document</th>
                                <th key="2">Date</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>
   {
                        documents?.map((item,key)=>(

                          item.type===3 &&

                            <tr>

                              <td>{item.name}</td>
                              <td>{moment(new Date(item.created_at)).format("DD MMM YYYY")} {moment(new Date(item.created_at)).format("HH:mm:ss")}</td>

                              <td>
                                <div className="list-contr">

                                  <a  href={item?.url} download className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Télécharger <i class="fa fa-download"></i></a>



                               </div>
                              </td>

                          </tr>


                        ))
                      }

                        </tbody>



                      </Table>

              </div>

          </Row>



    </Accordion.Body>
</Accordion.Item>

</Accordion>
<br/>
<Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header><strong>Comptes rendus et attestations</strong></Accordion.Header>
    <Accordion.Body>
   <br/>
    <div className='alert alert-primary'>Déposer et récupérer des comptes rendus et attestations.</div>
           <div className='flexitems'>
              <a href="javascript:void(0)"  onClick={() => handleClickfile(2)}   class="btnlink marauto">Déposer <i class="fa fa-plus"></i>
            <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"
                accept='image/*'
                onChange={(e) => handleFileChange(e,2)}
                ref={fileInput2}
            />
            </form>
           </a>

        </div>

        <br/> <br/>

  <Row className="mb-3"  >
      <div className='tavbleweb'>

                    <Table responsive  >
                          <thead>
                            <tr>

                                <th key="1">Document</th>
                                <th key="2">Date</th>
                                <th key="6" >Actions</th>
                            </tr>
                          </thead>
                        <tbody>


                             {
                        documents?.map((item,key)=>(

                          item.type===2 &&

                            <tr>

                              <td>{item.name}</td>
                              <td>{moment(new Date(item.created_at)).format("DD MMM YYYY")} {moment(new Date(item.created_at)).format("HH:mm:ss")}</td>

                              <td>
                                <div className="list-contr">

                                  <a  href={item?.url} download className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Voir">Télécharger <i class="fa fa-download"></i></a>



                               </div>
                              </td>

                          </tr>


                        ))
                      }

                        </tbody>



                      </Table>

              </div>

          </Row>



    </Accordion.Body>
</Accordion.Item>

</Accordion>
<br/>
                             </div>




                    </Col>
                </Row>

<Modal show={showAlert} onHide={handleCloseAlert} size="lg"  centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>



        <Row className="mb-3 abonnement text-center "  >


          <div className='itemimg col-md-3' style={{backgroundImage:"url('/images/product15.png')"}}></div>
          <h3 style={{marginBottom: '3px',marginTop: '18px'}}>Ahmed AITSAKEL</h3>
          <div style={{marginBottom: '30px'}}>
          <a href='' style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Contacter A.aitsakel</a>
          </div>


                  <div className='row'>


                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Dossier du patient: <br/><strong style={{textDecoration: 'underline' ,color: '#17a2b8'}}>Voir</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Séances:<br/> <strong> 4/5 séances effectuées</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Date:<br/> <strong>01/10/2022</strong></div>
                                         <div className='item col-md-3' style={{paddingBottom:'10px'}}>Heure:<br/> <strong>09:00</strong></div>

                  </div>




          <div>



             <div class="flexitems"><a href="javascript:void(0)" class="btnlink marauto">Modifier  </a><a href="javascript:void(0)"  class="btnlink marauto btnlinkjaun">Annuler   </a> <a href="javascript:void(0)"  class="btnlink marauto btnlinkred">Non honoré   </a> </div>




          </div>


          </Row>








        </Modal.Body>
        <Modal.Footer>
        {
          (eventconsulation ==1 || eventconsulation==2) &&

          <div className='alert alert-warning footermodflex'>Rappel : tout rendez-vous reporté ou annulé moins de 24 à l’avance sera prélevé.  <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Je confirme
          </Button></div>


        }


        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;
